<!--
 * @Author: zhuchaowei
 * @Date: 2024-05-24 11:50:44
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-29 15:10:18
 * @Description: 
-->
<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      destroy-on-close
      width="30%"
    >
      <div>
        <TextInput
          :config="{
            type: 'radio',
            label: '是否存在业务推荐费',
            option: [
              {
                label: '有',
                value: true,
              },
              {
                label: '无',
                value: false,
              },
            ],
          }"
          v-model="dataForm.referralFeeSwitch"
        ></TextInput>
        <TextInput
          v-if="dataForm.referralFeeSwitch"
          :config="{
            type: 'input',
            label: '业务推荐费率',
            isNum: true,
          }"
          v-model="dataForm.referralFee"
          ><span slot="inputAppend">%</span></TextInput
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button @click="submitForm" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
export default {
  name: "ReferralFeeDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "业务推荐",
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { TextInput },
  data() {
    return {
      dialogVisible: false,
      dataForm: { referralFee: "", referralFeeSwitch: false },
    };
  },
  watch: {
    show(n) {
      this.dialogVisible = n;
      n && (this.dataForm = { ...this.dataForm, ...this.detail });
    },
    dialogVisible(n) {
      if (!n) {
        this.clearForm();
        this.$emit("update:show", false);
      }
    },
  },
  methods: {
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
    },
    handleClose(done) {
      this.clearForm();
      done();
    },
    submitForm() {
      this.$emit("submit", this.dataForm);
      //this.clearForm();
      //交给父亲组件控制
      //this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .el-dialog__body {
  padding: 24px 32px;
  // border-top: 1px solid rgba(120, 120, 120, 0.3);
  // border-bottom: 1px solid rgba(120, 120, 120, 0.3);
}
.upload {
  width: 100%;
  ::v-deep .el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
      background: #f3f8ff !important;
    }
  }
}
.el-icon-upload {
  color: #4278c9;
}
.tips {
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.flex-center {
  display: flex;
  //justify-content: center;
  align-items: center;
}
.download-btn {
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0080ff;
  color: #0080ff;
  margin-bottom: 8px;
}
</style>
