var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"before-close":_vm.handleClose,"close-on-click-modal":false,"destroy-on-close":"","width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[_c('TextInput',{attrs:{"config":{
          type: 'radio',
          label: '是否存在业务推荐费',
          option: [
            {
              label: '有',
              value: true,
            },
            {
              label: '无',
              value: false,
            },
          ],
        }},model:{value:(_vm.dataForm.referralFeeSwitch),callback:function ($$v) {_vm.$set(_vm.dataForm, "referralFeeSwitch", $$v)},expression:"dataForm.referralFeeSwitch"}}),(_vm.dataForm.referralFeeSwitch)?_c('TextInput',{attrs:{"config":{
          type: 'input',
          label: '业务推荐费率',
          isNum: true,
        }},model:{value:(_vm.dataForm.referralFee),callback:function ($$v) {_vm.$set(_vm.dataForm, "referralFee", $$v)},expression:"dataForm.referralFee"}},[_c('span',{attrs:{"slot":"inputAppend"},slot:"inputAppend"},[_vm._v("%")])]):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }