<template>
  <el-dialog
    title="绑定项目"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div slot="title" class="title">绑定项目</div>
    <div class="main-content">
      <!-- 搜索刷新 -->
      <div>
        <el-button plain icon="el-icon-refresh" @click="getList"></el-button>
        <el-input
          placeholder="搜索"
          prefix-icon="el-icon-search"
          style="width: 330px; margin-left: 12px"
          v-model.lazy="searchParams.queryLike"
        >
        </el-input>
      </div>
      <!-- 表格 -->
      <div class="table">
        <el-table
          style="margin: unset"
          ref="multipleTable"
          :data="tableData"
          border
          highlight-current-row
          @current-change="handleSelectionChange"
          @selection-change="handleSelectionChange"
        >
          <div slot="empty">暂无数据</div>
          <el-table-column v-if="multiple" fixed type="selection" width="55">
          </el-table-column>
          <el-table-column
            label="项目编号"
            prop="projectSequence"
          ></el-table-column>
          <el-table-column
            label="项目名称"
            prop="projectName"
          ></el-table-column>
          <!-- <el-table-column label="上级项目" prop="parentName">
            <template slot-scope="scope">
              {{ scope.row.parentName || '--' }}
            </template>
          </el-table-column>
          <el-table-column
            label="项目负责人"
            prop="projectLeader"
          ></el-table-column> -->
          <el-table-column
            label="项目种类"
            prop="projectType"
          ></el-table-column>
          <el-table-column label="产品线" prop="productType"></el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <div class="pagination-total">
          共计{{ searchParams.total || 0 }}条记录
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="searchParams.pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchParams.pageSize"
          background
          layout="sizes, prev, pager, next, jumper"
          :total="searchParams.total"
        >
        </el-pagination>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="reset">重 置</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getBindProjectList, bindUserProject } from "@/api/project.js";
export default {
  name: "projectDialog2",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tableData: [],
      projectId: [],
      project: [],
      searchParams: {
        pageIndex: 1,
        pageSize: 10,
        enable: true,
        total: 0,
        queryLike: "",
      },
    };
  },
  watch: {
    dialogVisible: {
      handler(n) {
        if (n) {
          this.getList();
        }
      },
    },
    "searchParams.queryLike": {
      handler(n) {
        this.getList();
      },
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    handleSizeChange(e) {
      this.searchParams.pageSize = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.searchParams.pageIndex = e;
      this.getList();
    },
    getList() {
      getBindProjectList(this.searchParams).then((res) => {
        this.tableData = res.data.records;
        this.searchParams.total = res.data.total || 0;
      });
    },
    handleSelectionChange(e) {
      let res = e;
      if (!this.multiple) {
        res = [e];
      }
      this.projectId = res.map((item) => {
        return item.id;
      });
      this.project = res;
    },
    submit() {
      if (this.projectId.length) {
        this.$emit("submit", { projectId: this.projectId }, this.project);
      } else {
        this.handleClose();
      }
    },
    reset() {
      this.projectId = [];
      this.searchParams = {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        enable: true,
        queryLike: "",
      };
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(120, 120, 120, 0.3);
  border-radius: 8px 8px 8px 8px;
}
::v-deep .el-dialog__body {
}
.main-content {
  display: flex;
  flex-direction: column;
  .table {
    //  height: 300px;
    //  overflow: auto;
    margin-top: 10px;
  }
  .pagination {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-total {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
</style>
